export const ConfirmDialog = () => import('../../components/ConfirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExportButton = () => import('../../components/ExportButton.vue' /* webpackChunkName: "components/export-button" */).then(c => wrapFunctional(c.default || c))
export const FormComplaints = () => import('../../components/FormComplaints.vue' /* webpackChunkName: "components/form-complaints" */).then(c => wrapFunctional(c.default || c))
export const FormInquiries = () => import('../../components/FormInquiries.vue' /* webpackChunkName: "components/form-inquiries" */).then(c => wrapFunctional(c.default || c))
export const FormJobSearch = () => import('../../components/FormJobSearch.vue' /* webpackChunkName: "components/form-job-search" */).then(c => wrapFunctional(c.default || c))
export const FormOnlineComplaints = () => import('../../components/FormOnlineComplaints.vue' /* webpackChunkName: "components/form-online-complaints" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const PrintVersionReport = () => import('../../components/PrintVersionReport.vue' /* webpackChunkName: "components/print-version-report" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const AuthBasicRegister = () => import('../../components/auth/BasicRegister.vue' /* webpackChunkName: "components/auth-basic-register" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/auth/Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthPasswordReset = () => import('../../components/auth/PasswordReset.vue' /* webpackChunkName: "components/auth-password-reset" */).then(c => wrapFunctional(c.default || c))
export const DashboardCountersPanel = () => import('../../components/dashboard/CountersPanel.vue' /* webpackChunkName: "components/dashboard-counters-panel" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeatmap = () => import('../../components/dashboard/Heatmap.vue' /* webpackChunkName: "components/dashboard-heatmap" */).then(c => wrapFunctional(c.default || c))
export const DashboardStackedBar = () => import('../../components/dashboard/StackedBar.vue' /* webpackChunkName: "components/dashboard-stacked-bar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
